#sidebarContent {
    z-index: 6;
}

@media (min-width:1600px) {
.railLayout {
    grid-column: 4/span 2;
}
}

@media (max-width:1200px) {
    .railLayout {
        grid-column: 3/span 2;
    } 
}
