.pseudo-link:hover {
  text-decoration: unset;
}

.HeaderTitle {
  padding:9px;
}

.HeaderTitle .C-Heading {
  height: 33px;
  line-height: 33px;
  white-space: nowrap;
}

.PageTitle {
  display:flex;
  align-items:center
}
.PageTitle .C-Heading {
  line-height: 33px;
}

.GlobalUser {
  padding: .5rem;
}

.GlobalUserName {
  line-height: 2rem;
}

.logo {
  height: 80%;
  margin-right: 10px;
}

a.C-SideNav__Header {
  height: 62px;
  padding: 5px;
  margin-right:20px;
  position:relative
}

.avatarContainer {
  cursor: pointer;
}

.loggedInAs {
  cursor: default;
}

.loggedInAs:hover {
  background-color: unset;
}

.sideNavItemParent,.sideNavItemLink {
  display: block;
  font-weight: normal;
  text-align: left;
}

.sideNavItemLink:first-line,.sideNavItemParent:first-line {
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 1200px) {
.minimizable{
  margin-left:56px;
  grid-column:1/span 3
}
.fullWidthBodyClass {
  padding-left:24px
}

.C-Layout__globalActions { 
  grid-column: 2/span 2;
}
}

@media (max-width:1200px) {
  .minimizeIcon {
    display:none !important
  }
}

.buttonNotMinimized {
  align-items: center;
  background: transparent;
  border: 0;
  color: #3e72db;
  display: flex;
  outline:none;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 8px 12px;
  justify-content: right;
  width: 100% !important;
  min-height:41px;
  padding-right:20px !important
}

.buttonMinimized {
  align-items: center;
  background: transparent;
  border: 0;
  color: #3e72db;
  display: flex;
  outline:none;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 8px 12px;
  width: 100% !important;
  min-height:41px
}

.buttonNotMinimized:focus,.buttonMinimized:focus {
  background:#f0f0f0;
  box-shadow:none
}


.sideNavHeader {
  display:flex;
  align-items:center;
}

.sideNavHeaderPadding {
  display:flex;
  align-items:center;
}


.HeaderTitleMinimized {
  display:none
}

  @media(min-width:1600px) {
    .mainBody {
    grid-column:2/span 4 !important;
    padding-left:20px
  }   
  .mainBodyRail {
    grid-column:2/span 2 !important;
    padding-left:20px
  }   
   .mainBodyMinimized {
    grid-column:1/span 5  !important;
    padding-left:20px
  }
  .layoutHeaderLeft {
    grid-column:2
  }
  .bodyHeader {
    grid-column:2/span 2 
  }
}


.pageName {
  padding-left:6px;
  color: var(--canvas-theme-color--text);
}

.colorBasedOnTheme {
  color: var(--canvas-theme-color--text);
}

  @media(min-width: 768px) {
    .C-Layout__globalActions { 
      grid-column: 2/span 2;
    }
  }

  @media (min-width: 1600px) {
   .C-Layout__globalActions {
    grid-column: 3/span 3;
 }

  }
  .darkModeToggleLabel {
    color:var(--canvas-theme-color--text)
  }

.bold-uppercase {
  font-weight: bold;
  text-transform: uppercase;
  color:var(--canvas-font-color--default)
}

.button-text-vertical {
  display: flex;
  flex-direction: column;
}

@media(max-width:1200px) {
.menuButton {
  display:block !important;
  margin:auto !important
}
.headerLeftMargin {
  margin-left:56px !important;
}
}

@media(min-width:1200px) {
  .headerLeftPadding {
    padding-left: 56px;
  }
  }
